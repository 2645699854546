import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-md": "" } },
    [
      _c(
        VForm,
        {
          ref: "form",
          staticClass: "settings",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            VLayout,
            { staticClass: "wrap" },
            [
              _c(VFlex, { attrs: { xs12: "" } }, [_c("view-title")], 1),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c("notification-name", {
                    attrs: { notification: _vm.notification },
                    on: {
                      "update:notification": function($event) {
                        _vm.notification = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(VFlex, { attrs: { xs12: "" } }),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c("notification-scheduler", {
                    attrs: { notification: _vm.notification },
                    on: {
                      "update:notification": function($event) {
                        _vm.notification = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(VFlex, {
                staticStyle: { "padding-bottom": "50px" },
                attrs: { xs12: "" }
              }),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c("save-footer", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isModified,
                        expression: "isModified"
                      }
                    ],
                    attrs: {
                      cancelAction: _vm.cancel,
                      saveLabel: _vm.isNewNotif
                        ? "Create New Notification"
                        : "Save Changes",
                      saveAction: _vm.save
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }