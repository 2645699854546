<template>
  <v-container grid-list-md>
    <v-form ref="form" lazy-validation class="settings" v-model="valid">
      <v-layout class="wrap">
        <v-flex xs12>
          <view-title />
        </v-flex>
        <v-flex xs12>
          <notification-name :notification.sync="notification"> </notification-name>
        </v-flex>
        <v-flex xs12> </v-flex>
        <v-flex xs12>
          <!-- Commented out code is for future notif scheduling (CM-2781 Epic) -->
          <notification-scheduler :notification.sync="notification"></notification-scheduler>
        </v-flex>
        <v-flex xs12 style="padding-bottom: 50px"> </v-flex>
        <v-flex xs12>
          <save-footer
            v-show="isModified"
            :cancelAction="cancel"
            :saveLabel="isNewNotif ? 'Create New Notification' : 'Save Changes'"
            :saveAction="save"
          />
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import DateTime from 'luxon/src/datetime.js';
import { mapMutations, mapActions } from 'vuex';
import notificationName from './components/notification-name';
import notificationScheduler from './components/notif-scheduler';

export default {
  components: {
    notificationName,
    notificationScheduler,
  },
  data: () => ({
    valid: true,
    notification: {
      title: '',
      description: '',
      date_created: '',
      time_created: '',
      scheduled_release_date: '',
      scheduled_release_time: '',
    },
    backupNotification: null,
  }),
  methods: {
    ...mapActions('notifications', ['fetchNotifications']),
    ...mapMutations('notifications', ['setNotifications']),
    cancel() {
      // restore backupss
      this.$set(this, 'notification', cloneDeep(this.backupNotification));
      this.$refs.form.resetValidation();
      this.$toast('All your changes have been reset');
    },
    async save() {
      const isValidForm = this.$refs.form.validate();
      if (!this.valid || !isValidForm) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }
      let error;
      try {
        const timestamp = new Date(
          `${this.notification.scheduled_release_date}T${this.notification.scheduled_release_time}`,
        );
        const notif_payload = {
          text: this.notification.description,
          title: this.notification.title,
          realm: 'cdl',
          target: 'admin_panel',
          release_date: timestamp.toISOString(),
        };
        const res = await this.api.post('/notification', notif_payload);
        this.$store.commit('notifications/setNotifications', [
          res.data,
          ...this.$store.state.notifications.notifications,
        ]);
      } catch (err) {
        error = err;
      }
      if (error) {
        console.error(error);
        this.$toast.error(`Unable to save notification.`);
        return;
      }
      this.$toast('Saved successfully');
      this.$router.push({
        name: 'notification-manager',
      });
    },
  },
  computed: {
    isNewNotif() {
      return !this.$route.params.notification_id;
    },
    isModified() {
      return !isEqual(this.notification, this.backupNotification);
    },
    today() {
      return DateTime.local().toISODate();
    },
  },
  async mounted() {
    this.$set(this, 'backupNotification', cloneDeep(this.notification));
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 1,
      title: 'Create New In App Notification',
      to: { name: 'notification-new' },
    });
  },
};
</script>
